import { useLayoutEffect } from "react";
import Footer from "../components/footer/footer";
import Nav from "../components/navbar/nav";
import SpecComponents from "../components/spec/spec";
import TravelComponent from "../components/travel/travel";
import VideoComponent from "../components/video/background-video";
import {
    specImages,
    travelProps,
    travelProps2,
    travelProps3,
} from "../utils/location";

const Location: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Nav />
            <div className="contain-wrap">
                <VideoComponent />
                <div className="contain">
                    <div className="title">
                        <h1>
                            At the centre of <br></br> scientific excellence
                        </h1>
                        <p>
                            Uniquely positioned in one of the <br /> UK’s most
                            Innovative and best <br /> connected areas.
                        </p>
                    </div>
                    <img
                        src="../images/location/1.png"
                        alt="map"
                        width={"100%"}
                    />
                    <div className="accoms">
                        <div className="text loc">
                            <p>
                                <b></b>
                            </p>
                            <p style={{ color: "white" }}>
                                Kao Park is based in Harlow, one of London’s New
                                Towns on the Essex Hertfordshire Border. The
                                town is poised for huge growth with 23,000 new
                                homes planned as part of the Harlow and Gilston
                                Garden Town.
                                <br></br>
                                <br></br>
                                Within walking distance of the park there is a
                                Tesco supermarket, Co-op, Premier Inn,
                                independent café and the local Potters Arms
                                cookhouse. Harlow Innovation Park next door also
                                has a new café coming soon. David Lloyd leisure
                                centre planned to open in 2025.
                            </p>
                        </div>
                    </div>
                </div>{" "}
                <img
                    src="../images/triangle.svg"
                    alt="triangle"
                    className="triangle"
                />
            </div>
            <div className="loc-content">
                <div className="contain-wrap white">
                    <div className="contain">
                        <div className="accoms">
                            <h2>Innovation Corridor</h2>
                            <div className="left-text">
                                <p>
                                    The park benefits from its location in the
                                    heart of the London-Cambridge-Stansted
                                    Innovation Corridor, a centre of scientific
                                    excellence with a focus on theLife Sciences
                                    and technology sectors.
                                </p>
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="flex-wrapper">
                                <SpecComponents
                                    direction="direction"
                                    images={specImages}
                                />
                            </div>
                            <div className="flex-wrapper">
                                <img
                                    src="../images/location/2.png"
                                    alt="map"
                                    width={"100%"}
                                />
                            </div>
                        </div>
                        <div className="accoms">
                            <h2>Well connected</h2>
                            <div className="left-text">
                                <p>
                                    Just 13 miles from London Stansted, Harlow
                                    has excellent connections by road, rail and
                                    air.
                                </p>
                            </div>
                            <TravelComponent
                                title={travelProps.title}
                                images={travelProps.images}
                            />
                            <TravelComponent
                                title={travelProps2.title}
                                images={travelProps2.images}
                            />
                            <TravelComponent
                                title={travelProps3.title}
                                images={travelProps3.images}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};
export default Location;
