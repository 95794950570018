import { useState } from "react";
import "./floorplan-dropdown.scss";

const FloorplanDropDown = () => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const [multiTenant, setMultiTenant] = useState<boolean>(false);
    return (
        <>
            <div className="hero-dropdown">
                <div className="floorplan-content">
                    <p>
                        The following floor plans for the third floor are
                        indicative only, as the floorplates can be arranged
                        flexibly to accommodate various suite sizes. Further
                        information and help with space planning can be arranged
                        through the agents.
                    </p>
                    <div className="dropdown">
                        <div className="dropdown-section">
                            <div
                                className={`dropdown-title purple ${
                                    activeIndex == 0 ? "active" : "inactive"
                                }`}
                                onClick={() => {
                                    if (activeIndex === 0) {
                                        setActiveIndex(-1);
                                    } else {
                                        setActiveIndex(0);
                                    }
                                    setMultiTenant(false);
                                }}
                            >
                                <h3>Single Tenant</h3>
                                <div className="dropdown-icon">
                                    <img src="../images/building/dropdown.svg" />
                                </div>
                            </div>
                            {activeIndex == 0 && (
                                <div className="dropdown-content purple">
                                    <p>Whole Floor</p>
                                    <p>2,699 sq m</p>
                                    <p>29,050 sq ft</p>
                                </div>
                            )}
                        </div>
                        <div
                            className="multi-tenant"
                            onClick={() => {
                                setMultiTenant(!multiTenant);
                                setActiveIndex(-1);
                            }}
                        >
                            <h3>Multiple Tenants</h3>
                            <div className="dropdown-icon">
                                <img src="../images/building/dropdown.svg" />
                            </div>
                        </div>

                        {multiTenant && (
                            <>
                                <div className="dropdown-section">
                                    <div
                                        className={`dropdown-title l-red ${
                                            activeIndex == 1
                                                ? "active"
                                                : "inactive"
                                        }`}
                                        onClick={() => {
                                            activeIndex === 1
                                                ? setActiveIndex(-1)
                                                : setActiveIndex(1);
                                        }}
                                    >
                                        <p>Option One</p>
                                        <div className="dropdown-icon">
                                            <img src="../images/building/dropdown.svg" />
                                        </div>
                                    </div>
                                    {activeIndex == 1 && (
                                        <div className="dropdown-content l-red">
                                            <div className="row l-red">
                                                <p>Suite A</p>
                                                <p>1,245 sq m</p>
                                                <p>13,400 sq ft</p>
                                            </div>
                                            <div className="row l-red">
                                                <p>Suite B</p>
                                                <p>1,454 sq m</p>
                                                <p>15,650 sq ft</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="dropdown-section">
                                    <div
                                        className={`dropdown-title red ${
                                            activeIndex == 2
                                                ? "active"
                                                : "inactive"
                                        }`}
                                        onClick={() => {
                                            activeIndex === 2
                                                ? setActiveIndex(-1)
                                                : setActiveIndex(2);
                                        }}
                                    >
                                        <p>Option Two</p>
                                        <div className="dropdown-icon">
                                            <img src="../images/building/dropdown.svg" />
                                        </div>
                                    </div>
                                    {activeIndex == 2 && (
                                        <div className="dropdown-content red">
                                            <div className="row red">
                                                <p>Suite A</p>
                                                <p>928 sq m</p>
                                                <p>9,988 sq ft</p>
                                            </div>
                                            <div className="row red">
                                                <p>Suite B</p>
                                                <p>926 sq m</p>
                                                <p>9,967 sq ft</p>
                                            </div>
                                            <div className="row red">
                                                <p>Suite C</p>
                                                <p>730 sq m</p>
                                                <p>7,857 sq ft</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="dropdown-section">
                                    <div
                                        className={`dropdown-title yellow ${
                                            activeIndex == 3
                                                ? "active"
                                                : "inactive"
                                        }`}
                                        onClick={() => {
                                            activeIndex === 3
                                                ? setActiveIndex(-1)
                                                : setActiveIndex(3);
                                        }}
                                    >
                                        <p>Option Three</p>
                                        <div className="dropdown-icon">
                                            <img src="../images/building/dropdown.svg" />
                                        </div>
                                    </div>
                                    {activeIndex == 3 && (
                                        <div className="dropdown-content yellow">
                                            <div className="row yellow">
                                                <p>Suite A</p>
                                                <p>928 sq m</p>
                                                <p>9,988 sq ft</p>
                                            </div>
                                            <div className="row yellow">
                                                <p>Suite B</p>
                                                <p>917 sq m</p>
                                                <p>9,870 sq ft</p>
                                            </div>
                                            <div className="row yellow">
                                                <p>Suite C</p>
                                                <p>740 sq m</p>
                                                <p>7,965 sq ft</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="floorplan-image">
                    <img
                        src={`../images/building/floorplans/${activeIndex}.png`}
                    />
                </div>
            </div>
        </>
    );
};

export default FloorplanDropDown;
