import Footer from "../components/footer/footer";
import Nav from "../components/navbar/nav";
import VideoComponent from "../components/video/background-video";
import { useLayoutEffect } from "react";
import "../scss/styles.scss";
import GoogleMapReact from "google-map-react";

interface Props {
  text: string;
}

const AnyReactComponent: React.FC<Props> = ({ text }) => (
  <img src={text} style={{ width: "50px" }} alt="marker" />
);
const mapProps = {
  center: { lat: 51.77064251239167, lng: 0.1283589180006949 },
  zoom: 14,
};

const mapStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#ddd5cb",
      },
      {
        visibility: "on",
      },
    ],
  },
];

const Contact: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Nav />
      <div className="contain-wrap">
        <VideoComponent />
        <div className="contain">
          <div className="title">
            <h1>Find us</h1>
            <p>London Rd, Harlow CM17 9NA</p>
            <p>///when.visits.music</p>
          </div>
        </div>
        <img src="../images/triangle.svg" className="triangle" />
      </div>
      <div className="map-back">
        <div className="contain-wrap">
          <div className="contain">
            <div className="map">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBs9fOEZFVmtgCQT32tig-RtnryKmiWyjk",
                }}
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
                options={{
                  styles: mapStyle,
                }}
              >
                <AnyReactComponent
                  // @ts-ignore
                  lat={51.77064251239167}
                  lng={0.1283589180006949}
                  text={"../images/logo-col.svg"}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
