import React, { useLayoutEffect } from "react";
import Footer from "../components/footer/footer";
import Nav from "../components/navbar/nav";
import FloorplanSwiper from "../components/swipers/floorplan/swiper";
import "../scss/styles.scss";
import SpecComponents from "../components/spec/spec";
import VideoComponent from "../components/video/background-video";
import { Swipers, spec } from "../utils/building";
import { useInView, useAnimation } from "framer-motion";
import FloorplanDropDown from "../components/floorplanDropdown/floorplan-dropdown";

const Building: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Nav />
            <div className="hero">
                <div className="contain-wrap">
                    <VideoComponent />
                    <div className="contain">
                        <div className="no-bot title back">
                            <h1>
                                High-spec <br></br>space
                            </h1>
                            <p className="h1-child">
                                Modern 4-storey office building comprising
                                122,198 sq ft highly-specified accommodation
                                with an additional 13,599 sq ft of ancillary
                                buildings.
                            </p>
                        </div>
                        <img
                            src="../images/building/1.png"
                            alt="kao-one"
                            className="full-image"
                        />
                        <div className="accoms back">
                            <div className="text">
                                <p>
                                    <b></b>
                                </p>
                                <p style={{ color: "white" }}>
                                    Kao One acts as the headquarters for
                                    Raytheon and Arrow, demonstrating the appeal
                                    of the location.
                                    <br></br> <br></br>
                                    The building sits within its own demise with
                                    dedicated parking, hard and soft
                                    landscaping, cycle parking, shower and
                                    changing facilities, and a managed
                                    reception. The floor is also served by good
                                    lifts and service yard.
                                </p>
                            </div>
                        </div>
                    </div>
                    <img
                        src="../images/triangle.svg"
                        className="triangle-flipped-x"
                        alt="triangle"
                        style={{ marginTop: "-1px" }}
                    />
                </div>
                <div className="borders-flipped2 back">
                    <div className="contain-wrap white">
                        <div className="contain">
                            <div className=" no-top accoms back">
                                <h2>Accommodation</h2>
                                {/* <div className='text'>
                                    <p>
                                        <b>
                                            Kao One has highly-specified
                                            accommodation, with raised floors,
                                            suspended ceilings, and a full
                                            height reception incorporating a
                                            feature staircase.
                                        </b>
                                    </p>
                                    <p>
                                        The third floor is now available for
                                        occupancy for either a single tenant or
                                        can be split to accommodate two
                                        occupiers. It will be refitted
                                        internally with new ceilings, lighting,
                                        decoration, and floor coverings.
                                    </p>
                                </div>
                                <div className='contain'>
                                    <div className='swiper-padder'>
                                        <FloorplanSwiper slides={Swipers} />
                                    </div>
                                </div> */}
                               
                                    <FloorplanDropDown />
                               
                            </div>
                        </div>
                    </div>
                </div>

                <div className="spec">
                    <img
                        src="../images/triangle.svg"
                        className="triangle-flipped"
                        alt="triangle"
                        style={{ marginTop: "-1px" }}
                    />
                    <VideoComponent />
                    <div className="contain-wrap">
                        <div className="contain">
                            <h2 className="title">Specification</h2>
                            <SpecComponents images={spec} />
                        </div>
                    </div>
                    <img
                        src="../images/triangle.svg"
                        className="triangle-flipped-x"
                        alt="triangle"
                        style={{ marginBottom: "-1px" }}
                    />
                </div>

                <div className=" no-top accoms">
                    <div className="contain-wrap white">
                        <div className="contain park">
                            <h2>The Park</h2>
                            <div className="text desk-pad">
                                <p>
                                    <b>
                                        Founded on entrepreneurial spirit, with
                                        a long history of scientific and
                                        technological breakthrough.
                                    </b>
                                </p>
                                <p>
                                    In 1966 Charles Kao and George Hockman made
                                    a significant technological breakthrough
                                    with the invention of fibre optic cable.
                                    This changed the world of optical
                                    communications and paved the way for a host
                                    of modern innovations, including broadband
                                    Internet.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer buldingPage={true} />
        </>
    );
};

export default Building;
function useState(arg0: boolean): [any, any] {
    throw new Error("Function not implemented.");
}

function useEffect(arg0: () => void, arg1: any[]) {
    throw new Error("Function not implemented.");
}
