import Footer from "../components/footer/footer";
import Nav from "../components/navbar/nav";
import LottieAnimation from "react-lottie";
import animation from "./data.json";
import Swiper from "../components/swipers/base/swiper";
import "../scss/styles.scss";
import VideoComponent from "../components/video/background-video";
import { useLayoutEffect } from "react";

const slides = [
    {
        src: "../images/home/gallery/1.png",
    },
    {
        src: "../images/home/gallery/2.jpg",
    },
    {
        src: "../images/home/gallery/3.png",
    },
    {
        src: "../images/home/gallery/4.png",
    },
    {
        src: "../images/home/gallery/5.jpg",
    },
    {
        src: "../images/home/gallery/6.png",
    },
];

const Home: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    //@ts-ignore
    return (
        <>
            <Nav />
            <div className="gradient-background max-height mix-blend-screen">
                <VideoComponent />
                <div style={{ overflowX: "hidden" }}>
                    <LottieAnimation
                        options={defaultOptions}
                        height={"auto"}
                        width={"75%"}
                        style={{
                            zIndex: 2,
                            position: "relative",
                            mixBlendMode: "screen",
                            overflowX: "hidden",
                            maxWidth: "100%",
                        }}
                    />
                </div>
            </div>
            <div>
                <img
                    src="../images/home/1.jpg"
                    alt="kao-one"
                    className="full-image"
                />
            </div>
            <div className="contain-wrap">
                <VideoComponent />
                <div className="contain">
                    <div className="pad back">
                        <h1>
                            Where business <br></br>
                            meets innovation
                        </h1>
                        <p className="h1-child">
                            Third floor office suite offering up to 29,050 sq ft
                            of fully refurbished, Grade A accommodation, in the
                            heart of the influential London-Stansted-Cambridge
                            corridor.
                        </p>
                    </div>
                </div>
                <img
                    src="../images/triangle.svg"
                    alt="triangle"
                    className="triangle"
                />
            </div>
            <div className="borders">
                <div className="contain-wrap white">
                    <div className="contain">
                        <div className="page  swiper-wrapper">
                            <Swiper slides={slides} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Home;
